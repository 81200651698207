'use strict'

export default function () {
  const cardService = document.querySelectorAll('.services--card')
  const popupService = document.querySelectorAll('.popup-service')
  const closePopup = document.querySelectorAll('.popup-close')
  cardService.forEach((card, index) => {
    card.addEventListener('click', () => {
      popupService.forEach(item => item.classList.remove('active'))
      popupService[index].classList.add('active')
    })
  })

  closePopup.forEach((card, index) => {
    card.addEventListener('click', () => {
      popupService.forEach(item => item.classList.remove('active'))
    })
  })

}