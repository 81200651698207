'use strict'

export default function () {
  function scrollToSetion(event) {
    event.preventDefault()
    const href = event.currentTarget.getAttribute('href')
    const section = document.querySelector(href)

    // section.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'center'
    // })

    const topo = section.offsetTop;
    console.log(section)
    window.scrollTo({
      top: topo,
      behavior: 'smooth'
    })

  }
  const menus = document.querySelectorAll('.menu-anchor a')
  menus.forEach((menu) => { menu.addEventListener('click', scrollToSetion) })
  const buttons = document.querySelectorAll('.button-target')
  buttons.forEach(button => button.addEventListener('click', scrollToSetion))
}