'use strict';

import mainStyle from '../css/sass/main.scss';

import stepsFrom from './modules/stepsForm';
import placeholderForm from './modules/placeholderForm';
import mobileMenu from './modules/mobileMenu';
import scrollTo from './modules/scrollTop'
import targetScroll from './modules/targetScroll';
import popupServicos from './modules/popupServicos';

stepsFrom();
placeholderForm();
mobileMenu();
scrollTo();
targetScroll();
popupServicos();